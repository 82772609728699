import React, { useState } from 'react';
import './portfolio.css';

import MONITOR from '../../assets/monitor.png';
import IMG1 from '../../assets/magnolialwiaciarnia_laptop_fon.jpg';
import IMG2 from '../../assets/jachty_laptop_fon.jpg';
import IMG4 from '../../assets/tkautomation screens_no_logo.jpg';

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'STRONY FIRMOWE PROMOCJA -30%',
    desc: 'Strona firmowa to miejsce, gdzie powinieneś jak najlepiej opisać swoje produkty czy usługi oraz przedstawić to czym się zajmujesz. Powinna być przejrzysta i czytelna dla potencjalnego klienta, umożliwiać bezproblemowy kontakt z Tobą i realizację zleceń.',
    demo: 'https://magnoliakwiaciarnia.pl'
  },
  {
    id: 2,
    image: IMG2,
    title: 'SKLEPY INTERNETOWE',
    desc: 'Są dziś codziennością, bez której chyba już nikt nie wyobraża sobie funkcjonować. Również tendencje związane z rozwojem rynku ecommerce, jednoznacznie pokazują nam, że przyszłość sklepów internetowych wygląda co najmniej obiecująco. Jeśli więc masz pomysł na biznes online i zamierzasz wejść w nurt sprzedaży online – załóż sklep internetowy.',
    demo: 'https://jachty-autopol.pl/'
  },
  {
    id: 3,
    image: IMG1,
    title: 'STRONY WIZERUNKOWE PROMOCJA -30%',
    desc: 'Serwisy budujące markę, wizerunek firmy lub osoby – tak zwane strony Wizytówki. Są to strony, które w prosty i przejrzysty sposób pokazują Twoją markę i profil jej działalności oraz umożliwiają szybki kontakt.',
    demo: 'http://dancarkontenery.pl/'
  },
  {
    id: 4,
    image: IMG4,
    title: 'LANDING PAGES PROMOCJA -30%',
    desc: 'Inaczej strona docelowa to miejsce, na które kierowany jest odbiorca po kliknięciu w reklamę. Landing page, w przeciwieństwie do strony firmowej, realizuje jeden precyzyjnie określony cel. Dzięki temu masz pewność, że sprowadzony ruch przełoży się na wyższą konwersję, a odbiorca w prosty sposób będzie mógł zrealizować konkretną akcję.',
    demo: 'http://tkautomation.pl/'
  },
];

const Portfolio_PL = () => {
  const [showPopup, setShowPopup] = useState(true);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  return (
    <section id='portfolio' data-aos="zoom-out-up">
      <h2>Oferta</h2>
      <h3>Witamy w sercu tego, co robimy!</h3>
      <p>Łączę kreatywność, doświadczenie i zaangażowanie,
        aby dostarczyć szeroką gamę usług dostosowanych do Twoich indywidualnych potrzeb. Niezależnie od tego, czy jesteś startupem,
        ugruntowaną firmą, czy indywidualnym klientem chcącym zrobić wrażenie, jestem tu, aby zamienić Twoją wizję w rzeczywistość.</p>

      <a href="https://jachty-autopol.pl/">
        <div className="portfolio__image">
          <img src={MONITOR} alt="" />
        </div>
      </a>

      <div className="portfolio__items">
        {data.map(({ id, image, title, desc }) => (
          <div key={id} className='portfolio__item'>
            <div className="portfolio__item-image">
              <img src={image} alt={title} />
              <h3>{title}</h3>
              <ul>
                <li>{desc}</li>
              </ul>
            </div>
          </div>
        ))}
      </div>

      {showPopup && (
        <div className="popup">
          <div className="popup-inner">
            <h2>Wakacyjna Promocja!</h2>
            <p>Skorzystaj z naszej specjalnej oferty na strony internetowe z rabatem -30%! Aby skorzystać z promocji, napisz do nas maila z hasłem <strong>Sierpień30</strong> <br></br> <h4>email: kontakt@woosu.pl</h4>.</p>

            <div className="popup__buttons">
              <a class="btn btn--primary" href="#contact" onClick={handleClosePopup}>Napisz do nas!</a>
              <button className="popup-close" onClick={handleClosePopup}>Zamknij</button>
            </div>
          </div>
        </div>
      )
      }
    </section >
  );
};

export default Portfolio_PL;
