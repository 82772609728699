import React, { useEffect } from 'react'
import './realization.css'
import IMG1 from '../../assets/jachty_laptop_fon.jpg'
import IMG2 from '../../assets/tkautomation screens_no_logo.jpg'
import IMG3 from '../../assets/magnolialwiaciarnia_laptop_fon.jpg'
import IMG4 from '../../assets/dancar.jpg'
import IMG5 from '../../assets/pokonajlabirynt.png'
import IMG6 from '../../assets/zafakturuj.jpg'
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

gsap.registerPlugin(ScrollTrigger);

const data = [
  {
    id: 1,
    image: IMG1,
    title: 'jachty-autopol.pl',
    desc: 'Jachty-autopol.pl jest sklepem internetowym specjalizującym się w branży żeglarskiej, oferującym szeroki zakres usług, takich jak czartery jachtów na ciepłych wodach, szkolenia żeglarskie i rejsy morskie. Jako nowa platforma na rynku, prezentuje się jako nowoczesne i specjalistyczne miejsce dla miłośników żeglarstwa i morskich przygód.',
    demo: 'https://jachty-autopol.pl'

  },
  {
    id: 2,
    image: IMG2,
    title: 'tkautomation.pl',
    desc: 'Tkautomation.pl to dynamiczna strona internetowa firmy specjalizującej się w automatyce przemysłowej. Strona oferuje kompleksowe rozwiązania w zakresie automatyzacji procesów produkcyjnych, wykorzystując nowoczesne technologie i innowacyjne podejście. Intuicyjna nawigacja i nowoczesny design ułatwiają odwiedzającym zrozumienie oferty firmy, a dostępne recenzje i opinie klientów potwierdzają wysoką jakość usług.',
    demo: 'http://tkautomation.pl'

  },
  {
    id: 2,
    image: IMG3,
    title: 'magnoliakwiaciarnia.pl',
    desc: 'Strona magnoliakwiaciarnia.pl to wizytówka Kwiaciarni Magnolia z Włocławka, świadczącej kompleksowe usługi florystyczne od ponad 20 lat.Specjalizuje się w kreowaniu kompozycji na różne okazje, jak śluby, przyjęcia, czy pogrzeby. Strona, z łatwo dostępną ofertą i możliwością dostawy, skutecznie przyciąga klientów, prezentując się jako zaufany i profesjonalny dostawca usług florystycznych.',
    demo: 'http://magnoliakwiaciarnia.pl'

  },
  // {
  //   id: 3,
  //   image: IMG4,
  //   title: 'dancarkontenery.pl',
  //   desc: 'Ta strona prezentuje innowacyjne rozwiązania w zakresie kontenerów mieszkalnych i użytkowych. Strona wyróżnia się przejrzystym układem, szczegółowymi opisami produktów oraz inspirującymi przykładami zastosowania, co czyni ją atrakcyjną dla klientów szukających nowoczesnych i funkcjonalnych rozwiązań w dziedzinie architektury modułowej i wykończeń zewnętrznych.',
  //   demo: 'https://dancarkontenery.pl'

  // },
  // {
  //   id: 4,
  //   image: IMG6,
  //   title: 'zafakturuj.pl',
  //   desc: 'Strona zafakturuj.pl to innowacyjny serwis umożliwiający szybkie i efektywne tworzenie faktur online. Jest to idealne rozwiązanie dla małych i średnich przedsiębiorstw oraz freelancerów, którzy cenią sobie prostotę i wygodę w zarządzaniu fakturami. Strona wyróżnia się intuicyjnym interfejsem, umożliwiającym łatwe tworzenie, zarządzanie i wysyłanie faktur. Dodatkowo oferuje funkcje, takie jak monitoring płatności i personalizacja dokumentów, co sprawia, że jest cennym narzędziem w codziennej pracy biznesowej.',
  //   demo: 'https://zafakturuj.pl'

  // },
  {
    id: 5,
    image: IMG5,
    title: 'pokonajlabirynt.pl',
    desc: 'Strona pokonajlabirynt.pl to interaktywna platforma gier labiryntowych, która wyróżnia się różnymi poziomami trudności i atrakcyjną grafiką. Oprócz wersji przeglądarkowej, dostępna jest również aplikacja mobilna na Androida, co zwiększa dostępność gry i umożliwia zabawę w dowolnym miejscu i czasie. Strona zawiera funkcje takie jak tablice wyników i opinie użytkowników, a także praktyczne porady, co sprawia, że jest atrakcyjna dla różnych grup wiekowych i miłośników łamigłówek.',
    github: 'https://github.com',
    demo: 'https://pokonajlabirynt.pl'

  },
]


const Portfolio_PL = () => {
  useEffect(() => {

    gsap.to('.realizations__bg__square--right', {
      scrollTrigger: {
        trigger: '.realizations__bg__square--right',
        scrub: true,
        start: 'top bottom', // Animation start point
        end: 'bottom top', // Animation end point
      },
      y: 200, // Move up and down by 100px
      rotation: 90, // Rotate 360 degrees
      ease: 'none'
    });

    gsap.to('.realizations__bg__square--left', {
      scrollTrigger: {
        trigger: '.realizations__bg__square--left',
        scrub: true,
        start: 'top bottom',
        end: 'bottom top',
      },
      y: -200, // Move up and down by -100px (opposite direction)
      rotation: -50, // Rotate -360 degrees
      ease: 'none'
    });

  }, []);


  return (
    <section id='realizations'>
      <div className="realizations__wrapper">

        <h2>Przykładowe realizacje</h2>
        <p>Jestem dumny z moich osiągnięć i z dumą prezentuję poniżej kilka wyjątkowych projektów, nad którymi mieliśmy przyjemność pracować.
          Każde z tych przedsięwzięć stanowi wyraz mojej pasji i zaangażowania w dostarczanie kreatywnych i efektywnych rozwiązań dla moich klientów.
          Zapraszam do zapoznania się z moimi przykładowymi realizacjami:</p>

        <a href="https://jachty-autopol.pl/">
          <div className="realizations__image">
            {/* <img src={MONITOR} alt="" /> */}
          </div>
        </a>

      </div>

      <div className="realizations__items">

        <Swiper className="container realizations__container"
          spaceBetween={50}
          slidesPerView={1.3}
          // scrollbar={{ draggable: true }}
          loop={true}
          speed={2000}
          centeredSlides={false}
          breakpoints={{
            1024: {
              slidesPerView: 1.5,
              centeredSlides: true,
            }
          }}

        >
          {
            data.map(({ image, title, desc, demo }, index) => {
              return (
                <SwiperSlide className='realization' key={index}>
                  <a href={demo}>
                    <div className="realization__image">
                      <img src={image} alt="Avatar one" />
                    </div>
                    <h3 className='realization__title'>{title}</h3>
                    <p className='realization__desc'>{desc}</p>
                  </a>

                </SwiperSlide>
              )
            })
          }


        </Swiper>
      </div>

      <div className="realizations__bg__square--right"></div>
      <div className="realizations__bg__square--left"></div>
    </section >
  )
}

export default Portfolio_PL