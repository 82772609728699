import React, { useRef, useState } from 'react';
import { MdOutlineEmail } from 'react-icons/md';
import { BsMessenger } from 'react-icons/bs';
import { AiFillPhone } from 'react-icons/ai';
import './contact.css';
import emailjs from 'emailjs-com';

const Contact_PL = () => {
  const form = useRef();
  const [isConsentChecked, setIsConsentChecked] = useState(false); // Nowy stan dla checkboxa

  const sendEmail = (e) => {
    e.preventDefault();

    // Sprawdź, czy użytkownik zaznaczył checkbox
    if (!isConsentChecked) {
      alert('Musisz zaznaczyć pole wyboru o przetwarzaniu danych, aby kontynuować.');
      return; // Przerywa funkcję, jeśli checkbox nie jest zaznaczony
    }

    emailjs.sendForm('small.pl', 'template_xkik823', form.current, 'T4kwLmG3mnW7Kit7u')
      .then((result) => {
        console.log(result.text);
      }, (error) => {
        console.log(error.text);
      });
    e.target.reset();
  };

  return (
    <section id='contact'>
      <h5 data-aos="fade-right">Pomogę i odpowiem
        na wszelkie Twoje pytania. Indywidualny projekt i podejście
        – gwarantowane.</h5>
      <h2 data-aos="fade-left">Napisz do mnie!</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>kontakt@woosu.pl</h5>
            <a href="mailto:kontakt@woosu.pl" target="_blank" rel="noopener noreferrer">Wyślij wiadomość </a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Twoje imię i nazwisko' required />
          <input type="email" name='email' placeholder='Twój adres email' required />
          <textarea name="message" cols="30" rows="7" placeholder='Twoja wiadomość' required></textarea>
          <div className="checkbox-container">
            <input
              type="checkbox"
              id="consent"
              checked={isConsentChecked}
              onChange={() => setIsConsentChecked(!isConsentChecked)}
            />
            <label htmlFor="consent">Zgadzam się na przetwarzanie danych osobowych.</label>
          </div>
          <button type='submit' className='btn btn-primary'>Wyślij wiadomość</button>
        </form>
      </div>
    </section>
  )
}

export default Contact_PL;
