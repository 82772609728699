import React, { useEffect } from 'react'
import './about.css'
import aboutimg from '../../assets/about-image.png'
import { useInView } from "react-intersection-observer";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);


const About_PL = () => {

    const { about } = useInView({
        threshold: 0
    });

    useEffect(() => {
        gsap.to('.about__bg__square--right', {
            scrollTrigger: {
                trigger: '.about__bg__square--right',
                scrub: true,
                start: 'top bottom', // Animation start point
                end: 'bottom top', // Animation end point
            },
            y: 200, // Move up and down by 100px
            rotation: 90, // Rotate 360 degrees
            ease: 'none'
        });

        gsap.to('.about__bg__square--left', {
            scrollTrigger: {
                trigger: '.about__bg__square--left',
                scrub: true,
                start: 'top bottom',
                end: 'bottom top',
            },
            y: -200, // Move up and down by -100px (opposite direction)
            rotation: -90, // Rotate -360 degrees
            ease: 'none'
        });

        gsap.to('.about__image', {
            scrollTrigger: {
                trigger: '.about__image',
                scrub: true,
                start: 'top bottom',
                end: 'bottom top',
                markers: false,
                onUpdate: (self) => {
                    const elementHeight = window.innerHeight;
                    const rect = document.querySelector('.about__image').getBoundingClientRect();
                    const elementMiddle = rect.top + rect.height / 2;
                    const screenMiddle = elementHeight / 2;
                    const distanceToMiddle = Math.abs(elementMiddle - screenMiddle);

                    let opacity = 1 - (distanceToMiddle / screenMiddle);

                    opacity = Math.max(0, Math.min(opacity, 0.4));

                    gsap.set('.about__image', { opacity: opacity });
                },
            },
            ease: 'none'
        });

    }, []);

    return (
        <section id='about' ref={about}>
            <div className="container about__container">
                <div className="about__content">
                    <h2>Witaj w świecie innowacyjnych rozwiązań cyfrowych! </h2>
                    <p>
                        Pomagam przekształcać Twoje wizje w rzeczywistość. Specjalizuję się w tworzeniu responsywnych stron internetowych, zaawansowanych sklepów online oraz intuicyjnych aplikacji webowych. Moje projekty łączą funkcjonalność, estetykę i najnowsze technologie, aby zapewnić Ci przewagę w cyfrowym świecie.
                    </p>
                    <a href="#contact" className='btn btn-primary'>Skontaktuj się!</a>
                </div>
            </div>
            <div className="about__image__wrapper">
                <div className="about__image__desc">
                    <h2>Twoja wizja, nasze wykonanie.</h2>
                    <p>Oferuje kompleksowe rozwiązania w zakresie tworzenia stron internetowych, e-commerce i aplikacji webowych.
                        <br></br> Moje projekty charakteryzują się unikalnym połączeniem funkcjonalności, estetyki i najnowszych technologii. <br></br> Pomagam marce osiągnąć cyfrowy sukces.</p>
                </div>
                <div className="about__image">
                    <img src={aboutimg} alt="" />
                </div>
            </div>

            <div className="about__bg__square--right"></div>
            <div className="about__bg__square--left"></div>
        </section>
    )
}

export default About_PL